import BigNumber from "bignumber.js";

export function shortenAddress(address, chars = 6) {
  return address
    ? `${address.substring(0, chars)}...${address.substring(
      address.length - chars
    )}`
    : "";
}

// 截取小数
export const decimalBit = (num, count = 2) => {
  if (isNaN(num) && num) {
    return num;
  }
  if (!num || num == " " || num == 0) {
    if (count == 0) {
      return 0;
    }
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `0.${fillZero}`;
  }

  const numList = String(num).split(".");
  if (count === 0) {
    return numList[0];
  }
  if (numList.length > 1) {
    const decimalAfterQuantity = `${String(numList[1]).substr(0, count)}`;
    let fillZero = "";
    const fillZeroQuantity = count - decimalAfterQuantity.length;
    if (fillZeroQuantity > 0) {
      for (let i = 0; i < fillZeroQuantity; i++) {
        fillZero += "0";
      }
    }
    return `${numList[0]}.${decimalAfterQuantity}${fillZero}`;
  } else {
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `${num}.${fillZero}`;
  }
};

// 获取单位
export const getUnit = decimal => {
  const wei =
    decimal == 3
      ? "kwei"
      : decimal == 6
        ? "mwei"
        : decimal == 9
          ? "gwei"
          : decimal == 12
            ? "szabo"
            : decimal == 15
              ? "finney"
              : decimal == 18
                ? "ether"
                : "ether";
  return wei;
};

export const getLocalUserData = () => {
  // const address = window.web3.eth.coinbase.toLowerCase();
  // const userDataStr = localStorage.getItem(`${address}Data`);
  const userDataStr = localStorage.getItem(`userData`);
  const userData = JSON.parse(userDataStr);
  return userData;
};

const minuteInS = 60;
const hourInS = 60 * minuteInS;
const dayInS = 24 * hourInS;

export const buildCountDown = (secondRemaining) => {
  // console.log("secondRemaining", secondRemaining)
  const d = parseInt(secondRemaining / dayInS)
  const h = parseInt((secondRemaining - d * dayInS) / hourInS)
  const m = parseInt((secondRemaining - d * dayInS - h * hourInS) / minuteInS)
  const s = parseInt((secondRemaining - d * dayInS - h * hourInS - m * minuteInS))
  // console.log(d, h, m, s)
  return {
    getSecond: () => {
      return s
    },
    getMinute: () => {
      return m
    },
    getHour: () => {
      return h
    },
    getDay: () => {
      return d
    }
  }

}

export const NFTEnum = ["Detective", "Sergeant", "Lieutenant"]

export const getNFTMode = (i, mod) => {
  let sequence = i % mod
  if (sequence == 0) {
    sequence = mod
  }
  return sequence
}

export const groupNFT = (nfts, mod) => {
  let nfti = {}
  nfts.map((nft) => {
    let sequence = getNFTMode(nft.sequence, mod)
    if (!nfti[sequence]) {
      nfti[sequence] = 0
    }
    nfti[sequence]++
  })

  return nfti
}

// 精度转化 乘
export function nToBN(value, decimals = 18) {
  return new BigNumber(value).times(new BigNumber(10).pow(decimals)).toString();
}

// 精度转化 除
export function bNToN(value, decimals = 18) {
  return new BigNumber(value).div(new BigNumber(10).pow(decimals)).toString();
}

// 获取utc当前时间
export function getUTCTime() {
  let d1 = new Date()
  let d2 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds())
  return Date.parse(d2)
}