import axios from "axios";
import config from "../config";
import {message} from 'antd'

let axiosRequestArr = [];
let _option = {
  hasProgress: true // 显示进度条的效果
};

axios.defaults.baseURL = config.apiUrl;

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // console.log(config);

    // 如果请求设置了cancelToken
    config.cancelToken = config.cancelToken
      ? config.cancelToken
      : new axios.CancelToken(cancel => {
          axiosRequestArr.push(cancel);
        });

    return config;
  },
  error => {
    console.log(error);
    return Error("服务请求报错");
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    axiosRequestArr.pop();
    // if (response.data.code !== 0) {
    //   message.error(response.data.msg)
    // }
    return response.data;
  },
  // 服务器状态码不是200的情况
  error => {
    axiosRequestArr.pop();
    console.log(error);
    return Error("服务连接错误");
  }
);

export function put(url, data, option) {
  return new Promise((resolve, reject) => {
    const obj = {
      method: "put",
      url: url,
      data: data,
      ..._option,
      ...option
    };
    // if (window.web3.eth.coinbase) {
    // const address = window.web3.eth.coinbase.toLowerCase();
    const userDataStr = localStorage.getItem(`userData`);
    const userData = JSON.parse(userDataStr);

    if (userDataStr) {
      obj.headers = { Authorization: userData.token };
    }
    // }
    axios(obj)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function post(url, data, option) {
  return new Promise((resolve, reject) => {
    const obj = {
      method: "post",
      url: url,
      data: data,
      ..._option,
      ...option
    };
    // if (window.web3.eth.coinbase) {
    // const address = window.web3.eth.coinbase.toLowerCase();
    const userDataStr = localStorage.getItem(`userData`);
    const userData = JSON.parse(userDataStr);

    if (userDataStr) {
      obj.headers = { Authorization: userData.token };
    }
    // }
    axios(obj)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function get(url, data, option) {
  return new Promise((resolve, reject) => {
    const obj = {
      method: "get",
      url: url,
      params: data,
      ..._option,
      ...option
    };
    // if (window.web3.eth.coinbase) {
    // const address = window.web3.eth.coinbase.toLowerCase();
    const userDataStr = localStorage.getItem(`userData`);
    const userData = JSON.parse(userDataStr);

    if (userDataStr) {
      obj.headers = { Authorization: userData.token };
    }
    // }
    axios(obj)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function all(axiosFuns) {
  return new Promise(resolve => {
    axios.all(axiosFuns).then(
      axios.spread((...resArr) => {
        resolve(resArr);
      })
    );
  });
}

export default {
  post,
  get,
  put,
  all,
  CancelToken: axios.CancelToken
};
