import Web3 from "web3";
import BigNumber from "bignumber.js";
import { MaxUint256 } from "@ethersproject/constants";
import { unit, decimalBit } from "../utils/index";
import config from "../config";
// import Erc20 from "./abi/IERC20.json";
// import erc20ABI from "./abi/erc20Abi";
// import CSInsurance from "./abi/CSInsurance.json";
// import RewardPool from "./abi/RewardPool.json";
// import GNED from "./abi/gNEDABI.json";
// import VoteDAO from "./abi/VoteDAO.json";
// import { showTip } from "../components/common/CMessageTip";
import i18next from "i18next";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

// eth地址
const ETH_ADDR = config.mainChainAddr;

class Contract {
  web3;
  timer;
  timer1;
  timer2;
  feeHash;
  t;
  constructor() {
    try {
      if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      const url = new Web3.providers.HttpProvider(config.ethRpc);
      this.web3 = new Web3(web3.currentProvider);
      this.web3RPC = new Web3(url);

      // this.contractRPC = new this.web3RPC.eth.Contract(
      //   Erc20,
      //   config.posRootERC20
      // );
      // this.contractUSDT = new this.web3.eth.Contract(
      //   Erc20,
      //   config.posRootERC20
      // );

      // // IPST 合约
      // // this.contractRPCIPST = new this.web3RPC.eth.Contract(
      // //   Erc20,
      // //   config.IPSTToken
      // // );
      // this.contractRPCIPST = new this.web3.eth.Contract(
      //   Erc20,
      //   config.IPSTToken
      // );
      // this.contractIPST = new this.web3.eth.Contract(Erc20, config.IPSTToken);

      // // NED 合约
      // // this.contractRPCNED = new this.web3RPC.eth.Contract(
      // //   Erc20,
      // //   config.NEDToken
      // // );
      // this.contractRPCNED = new this.web3.eth.Contract(Erc20, config.NEDToken);
      // this.contractNED = new this.web3.eth.Contract(Erc20, config.NEDToken);

      // // GNED合约
      // // this.contractGNEDRPC = new this.web3RPC.eth.Contract(
      // //   Erc20,
      // //   config.GNEDToken
      // // );
      // this.contractGNEDRPC = new this.web3.eth.Contract(
      //   Erc20,
      //   config.GNEDToken
      // );

      // // this.contractGNED = new this.web3RPC.eth.Contract(GNED, config.GNEDToken);
      // this.contractGNED = new this.web3.eth.Contract(GNED, config.GNEDToken);

      // // this.contractCSI = new this.web3RPC.eth.Contract(
      // //   CSInsurance,
      // //   config.CSInsurance
      // // );
      // this.contractCSI = new this.web3.eth.Contract(
      //   CSInsurance,
      //   config.CSInsurance
      // );
      // this.contract = new this.web3.eth.Contract(
      //   CSInsurance,
      //   config.CSInsurance
      // );

      // // 资金池
      // // this.contractPoolRpc = new this.web3RPC.eth.Contract(
      // //   RewardPool,
      // //   config.RewardPool
      // // );
      // this.contractPoolRpc = new this.web3.eth.Contract(
      //   RewardPool,
      //   config.RewardPool
      // );
      // this.contractPool = new this.web3.eth.Contract(
      //   RewardPool,
      //   config.RewardPool
      // );

      // //治理
      // // this.contractVoteRpc = new this.web3RPC.eth.Contract(
      // //   VoteDAO,
      // //   config.VoteDAO
      // // );
      // this.contractVoteRpc = new this.web3.eth.Contract(
      //   VoteDAO,
      //   config.VoteDAO
      // );
      // this.contractVote = new this.web3.eth.Contract(VoteDAO, config.VoteDAO);
    } catch (e) {
      console.log(e.message);
    }
  }

  // 获取当前钱包地址
  async getCurrentAccount() {
    try {
      return window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(res => {
          return res[0];
        });
    } catch (e) {
      console.log(e.message);
    }
  }

  // //  获取GNED的精度
  // async GNEDDec() {
  //   return unit(await this.contractGNEDRPC.methods.decimals().call());
  // }
  // //  获取GNED的质押量
  // async GNEDAmount() {
  //   const dec = unit(await this.contractGNEDRPC.methods.decimals().call());
  //   // const res = await this.contractVoteRpc.methods.totalAmount().call();
  //   const res = await this.contractVote.methods.totalAmount().call();
  //   return decimalBit(BigNumber(res).div(dec).toFixed(), 6);
  // }
  // //  获取NED的精度
  // async NEDDec() {
  //   return unit(await this.contractRPCNED.methods.decimals().call());
  // }
  // //  获取NED兑换GNED比率
  // async toGNED() {
  //   const dec = unit(await this.contractGNEDRPC.methods.decimals().call());
  //   const nedDec = unit(await this.contractRPCNED.methods.decimals().call());
  //   const res = await this.contractGNED.methods
  //     .balanceTo(BigNumber(1).times(nedDec).toFixed())
  //     .call();
  //   return {
  //     amount: decimalBit(BigNumber(res).div(dec).toFixed(), 6),
  //     oldAmount: BigNumber(res).div(dec).toFixed()
  //   };
  // }
  // //  获取GNED兑换NED比率
  // async toNED() {
  //   const dec = unit(await this.contractGNEDRPC.methods.decimals().call());
  //   const nedDec = unit(await this.contractRPCNED.methods.decimals().call());
  //   const res = await this.contractGNED.methods
  //     .balanceFrom(BigNumber(1).times(dec).toFixed())
  //     .call();
  //   return {
  //     amount: decimalBit(BigNumber(res).div(nedDec).toFixed(), 6),
  //     oldAmount: BigNumber(res).div(nedDec).toFixed()
  //   };
  // }

  // // 获取USDT余额
  // async getBalance() {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   const balance = await this.contractUSDT.methods.balanceOf(addr).call();
  //   return {
  //     balance: decimalBit(new BigNumber(balance).div(decimals).toFixed()),
  //     oldData: new BigNumber(balance).div(decimals).toFixed()
  //   };
  // }
  // // 获取IPST余额
  // async getIPSTBalance() {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractRPCIPST.methods.decimals().call());
  //   const balance = await this.contractRPCIPST.methods.balanceOf(addr).call();
  //   return {
  //     balance: decimalBit(new BigNumber(balance).div(decimals).toFixed(), 6),
  //     allBalance: new BigNumber(balance).div(decimals).toFixed()
  //   };
  // }

  // async getTokenDecimals(tokenAddress) {
  //   try {
  //     if (tokenAddress === ETH_ADDR) {
  //       return 18;
  //     }
  //     const contract = new this.web3.eth.Contract(erc20ABI, tokenAddress);
  //     return await contract.methods.decimals().call();
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  // }

  // // 查询购买是否授权
  // async getCSISApprove(token) {
  //   const addr = await this.getCurrentAccount();
  //   const tokenDecimals = await this.getTokenDecimals(token);
  //   const amount = await this.contractUSDT.methods.allowance(addr, config.CSInsurance).call();
  //   return new BigNumber(amount)
  //     .div(new BigNumber(10).pow(tokenDecimals))
  //     .toString();
  // }

  // // 购买授权
  // async CSIApprove() {
  //   const addr = await this.getCurrentAccount();
  //   return this.contractUSDT.methods
  //     .approve(config.CSInsurance, MaxUint256)
  //     .send({
  //       from: addr
  //     });
  // }

  // // 购买合约安全险
  // async buy(order, sign, amount, hash) {
  //   const sig = sign.map(el => {
  //     return [el.v.toString(), el.r, el.s];
  //   });
  //   const addr = await this.getCurrentAccount();
  //   // const decimals = unit(await this.contractRPC.methods.decimals().call());
  //   // order.amount = new BigNumber(order.amount).times(decimals).toFixed()
  //   const orderData = [
  //     order.initOwner,
  //     order.CSIAddress,
  //     order.collateral,
  //     order.amount,
  //     order.contractAddress,
  //     order.status,
  //     order.makeTime,
  //     order.expirationTime,
  //     order.multiple,
  //     order.salt,
  //     order.tokenToNEIPrice
  //   ];
  //   const checkRes = await this.buyCheck(hash, orderData, sig);
  //   if (checkRes) {
  //     try {

  //       return await this.contract.methods
  //         .buyCSInsurance(orderData, sig)
  //         .send({ from: addr });
  //     } catch (e) {
  //       console.log(e)
  //       return e
  //     }
  //   } else {
  //     return { checkRes: false };
  //   }
  // }

  // // 申诉校验
  // async appealCheck(id) {
  //   const addr = await this.getCurrentAccount();
  //   const result = await this.contractCSI.methods
  //     .checkAgain(id)
  //     .call({ from: addr });
  //   return result;
  // }
  // // 申请理赔校验
  // async applyCheck(id, amount, questionTime) {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   const num = BigNumber(amount).times(decimals).toFixed();
  //   try {
  //     const result = await this.contractCSI.methods
  //       .checkApply(id, num, questionTime)
  //       .call({ from: addr });
  //     return result;
  //   } catch (e) {
  //     console.error("applyCheckerr ", e)
  //     return false;
  //   }
  // }

  // // 保单校验
  // async buyCheck(hash, order, sig) {
  //   const addr = await this.getCurrentAccount();
  //   try {
  //     const result = await this.contractCSI.methods
  //       .validateOrder(hash, order, sig)
  //       .call({ from: addr });
  //     return result;
  //   } catch (e) {
  //     console.log(e.message);
  //     if (e.message.indexOf("takeAmount err") > -1) {
  //       i18next.language.toUpperCase() === "ZH"
  //         ?
  //         // message.error("超出剩余总量")
  //         showTip(
  //           "copy",
  //           "error",
  //           '超出剩余总量',
  //           ''
  //         )

  //         :
  //         showTip(
  //           "copy",
  //           "error",
  //           'Exceed the remaining total',
  //           ''
  //         )
  //       // message.error("Exceed the remaining total");
  //     } else if (e.message.indexOf("subtraction overflow") > -1) {
  //       i18next.t("insurance.超出最大购买额度");
  //     } else {
  //       // message.error(e.message);
  //       showTip(
  //         "copy",
  //         "error",
  //         e.message,
  //         ''
  //       )
  //     }
  //   }
  // }

  // // 获取剩余可购买额度
  // async getRemainingPurchasable() {
  //   const poolData = await this.poolInfo()
  //   const takeRate = await this.getTakeRate()
  //   const baseRate = this.getBaseRate()
  //   const numMul = await this.contractPoolRpc.methods.numMul().call();
  //   const num = poolData.totalAmount * numMul * takeRate / baseRate - poolData.maxTakeAmount
  //   return num
  // }

  // async getTakeRate() {
  //   const res = await this.contractCSI.methods.takeRate().call();
  //   return res
  // }

  // async getOrderRate() {
  //   const res = await this.contractCSI.methods.orderRate().call();
  //   return res
  // }

  // // 常量
  // getBaseRate() {
  //   return 10000
  // }

  // // 资金池
  // async poolInfo() {
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   const info = await this.contractPoolRpc.methods.poolInfo().call();
  //   return {
  //     totalAmount: new BigNumber(info.totalAmount).div(decimals).toFixed(), // 资金总额
  //     earnAmount: new BigNumber(info.earnAmount).div(decimals).toFixed(), // 累计赚取金额
  //     loseAmount: new BigNumber(info.loseAmount).div(decimals).toFixed(), // 累计赔付金额
  //     maxTakeAmount: new BigNumber(info.maxTakeAmount).div(decimals).toFixed() // 当前资金池占用金额
  //   };
  // }

  // async getMagnifyRatio() {
  //   try {
  //     const magnifyRate = await this.contractPoolRpc.methods.numMul().call();
  //     return {
  //       magnifyRate: magnifyRate
  //     };
  //   } catch (e) {
  //     console.log(e);
  //     return {
  //       magnifyRate: 0
  //     };
  //   }
  // }

  // // 资金池人数
  // async people() {
  //   return await this.contractPoolRpc.methods.insurerID().call();
  // }
  // // 资金池可用
  // async poolAmount() {
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   const valible = await this.contractPoolRpc.methods.getUseAmount().call();
  //   return decimalBit(new BigNumber(valible).div(decimals).div(10).toFixed());
  // }
  // // 解锁时间
  // async unlockTime() {
  //   // const addr = await this.getCurrentAccount();
  //   // return await this.contractPoolRpc.methods.insurerUnlockTime(addr).call();

  //   const account = await this.getCurrentAccount();
  //   try {
  //     const unlockTime = await this.contractPoolRpc.methods.insurerUnlockTime(account).call();
  //     return unlockTime;
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  // }
  // // 解锁时间
  // async currentTime() {
  //   return await this.contractPoolRpc.methods.getCurrTime().call();
  // }


  // // 查询解锁时间
  // async getUnlockTime() {
  //   const account = await this.getCurrentAccount();
  //   try {
  //     const unlockTime = await this.contractPoolRpc.methods
  //       .insurerUnlockTime(account)
  //       .call();
  //     return unlockTime;
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  // }
  // // 查询当前时间戳
  // async getCurrTime() {
  //   try {
  //     const currTime = await this.contractPoolRpc.methods
  //       .getCurrTime()
  //       .call();
  //     return currTime;
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  // }
  // // 查询存入是否授权
  // async queryApprove() {
  //   const addr = await this.getCurrentAccount();
  //   const tokenDecimals = await this.getTokenDecimals(config.posRootERC20);
  //   const amount = await this.contractUSDT.methods.allowance(addr, config.RewardPool).call();
  //   return new BigNumber(amount)
  //     .div(new BigNumber(10).pow(tokenDecimals))
  //     .toString();
  // }
  // // 查询usdt到CPST
  // async lpToToken() {
  //   const res = await this.contractPoolRpc.methods.getLpToToken().call();
  //   return BigNumber(res).div(unit(18)).toFixed();
  // }
  // // 查询CPST到usdt
  // async tokenToLp() {
  //   const res = await this.contractPoolRpc.methods.getTokenToLp().call();
  //   return BigNumber(res).div(unit(18)).toFixed();
  // }

  // // 存入授权
  // async desipotApprove(to) {
  //   try {
  //     const addr = await this.getCurrentAccount();
  //     return await this.contractUSDT.methods
  //       .approve(config.RewardPool, MaxUint256)
  //       .send({
  //         from: addr
  //       });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 存入
  // async desipot(amount) {
  //   console.log('amountamountamount', amount)
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   amount = new BigNumber(amount).times(decimals).toFixed();
  //   const addr = await this.getCurrentAccount();
  //   try {
  //     console.log('trueueue')
  //     return await this.contractPool.methods.addPoolAmount(amount).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }
  // // 查询取出是否授权
  // async queryWithdraw() {
  //   const addr = await this.getCurrentAccount();
  //   const tokenDecimals = await this.getTokenDecimals(config.IPSTToken);
  //   const amount = await this.contractRPCIPST.methods
  //     .allowance(addr, config.RewardPool)
  //     .call();
  //   return new BigNumber(amount)
  //     .div(new BigNumber(10).pow(tokenDecimals))
  //     .toString();
  // }

  // // 取出授权
  // async withdrawApprove(to) {
  //   try {
  //     const addr = await this.getCurrentAccount();
  //     return await this.contractIPST.methods
  //       .approve(config.RewardPool, MaxUint256)
  //       .send({
  //         from: addr
  //       });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 取出
  // async withdraw(amount) {
  //   try {

  //     const addr = await this.getCurrentAccount();
  //     const decimals = unit(await this.contractRPCIPST.methods.decimals().call());
  //     amount = new BigNumber(amount).times(decimals).toFixed();
  //     return await this.contractPool.methods.claimInsurer(amount).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // get delay time for claim
  // async getDelayTime() {
  //   const result = await this.contractCSI.methods.applyDelyTime().call();
  //   return result
  // }

  // // 获取申请理赔的费用
  // async applyFee() {
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   const fee = await this.contractCSI.methods.applyAmount().call();
  //   return BigNumber(fee).div(decimals).toFixed();
  // }

  // // 申请理赔
  // async claim(id, amount, questionTime) {
  //   try {

  //     const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //     const addr = await this.getCurrentAccount();
  //     const num = BigNumber(amount).times(decimals).toFixed();
  //     return await this.contract.methods.applyFor(id, num, questionTime).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 查询保单信息
  // async policyInfo(id) {
  //   return await this.contractCSI.methods.csiOrder(id).call();
  // }

  // // 查询是不是大众评审
  // async getIsPub() {
  //   const addr = await this.getCurrentAccount();
  //   // return await this.contractVoteRpc.methods.pubToID(addr).call();
  //   return await this.contractVote.methods.pubToID(addr).call();
  // }

  // // 查询是不是专业评审
  // async getIsPr() {
  //   const addr = await this.getCurrentAccount();
  //   // return this.contractVoteRpc.methods.getPRContains(addr).call();
  //   return await this.contractVote.methods.getPRContains(addr).call();
  // }

  // // 查询NED钱包余额
  // async getNED() {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractRPCNED.methods.decimals().call());
  //   const res = await this.contractRPCNED.methods.balanceOf(addr).call();
  //   return decimalBit(BigNumber(res).div(decimals).toFixed(), 6);
  // }

  // // 查询NED精度
  // async getNEDDecimals() {
  //   return unit(await this.contractRPCNED.methods.decimals().call());
  // }

  // // 查询质押总金额
  // async getTotal() {
  //   const decimals = unit(await this.contractGNEDRPC.methods.decimals().call());
  //   // const res = await this.contractVoteRpc.methods.totalAmount().call();
  //   const res = await this.contractVote.methods.totalAmount().call();
  //   return decimalBit(BigNumber(res).div(decimals).toFixed(), 6);
  // }

  // // 查询质押总金额
  // async getGnedDecimal() {
  //   const decimals = unit(await this.contractGNEDRPC.methods.decimals().call());
  //   return decimals
  // }

  // // 查询当前质押量
  // async revieverInfo() {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractGNEDRPC.methods.decimals().call());
  //   // const res = await this.contractVoteRpc.methods.revieverInfo(addr).call();
  //   const res = await this.contractVote.methods.revieverInfo(addr).call();
  //   return {
  //     amount: decimalBit(BigNumber(res.amount).div(decimals).toFixed(), 6),
  //     unLockTime: res.unLockTime,
  //     oldAmount: res.amount
  //   };
  // }

  // // 查询分红信息
  // async periodInfo(num) {
  //   // return await this.contractVoteRpc.methods.periodInfo(num).call();
  //   return await this.contractVote.methods.periodInfo(num).call();
  // }

  // // 查询分红期数
  // async caculateID() {
  //   // return await this.contractVoteRpc.methods.caculateID().call();
  //   return await this.contractVote.methods.caculateID().call();
  // }

  // // 查询质押是否授权
  // async queryVoteWithdraw() {
  //   const addr = await this.getCurrentAccount();
  //   const tokenDecimals = await this.getTokenDecimals(config.NEDToken);
  //   const amount = await this.contractRPCNED.methods.allowance(addr, config.VoteDAO).call();
  //   return new BigNumber(amount)
  //     .div(new BigNumber(10).pow(tokenDecimals))
  //     .toString();
  // }
  // // 查询解开质押是否授权
  // async queryUnVoteWithdraw() {
  //   const addr = await this.getCurrentAccount();
  //   const tokenDecimals = await this.getTokenDecimals(config.GNEDToken);
  //   const amount = await this.contractGNEDRPC.methods.allowance(addr, config.VoteDAO).call();
  //   return new BigNumber(amount)
  //     .div(new BigNumber(10).pow(tokenDecimals))
  //     .toString();
  // }

  // // 质押授权
  // async voteApprove(to) {
  //   try {
  //     const addr = await this.getCurrentAccount();
  //     return await this.contractNED.methods.approve(config.VoteDAO, MaxUint256).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }
  // // 解开质押授权
  // async unVoteApprove(to) {
  //   try {
  //     const addr = await this.getCurrentAccount();
  //     return await this.contractGNED.methods.approve(config.VoteDAO, MaxUint256).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 质押
  // async deposit(amount) {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractRPCNED.methods.decimals().call());
  //   try {
  //     return await this.contractVote.methods
  //       .deposit(BigNumber(amount).times(decimals).toFixed())
  //       .send({
  //         from: addr
  //       });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 解压
  // async voteWithdraw(amount) {
  //   try {
  //     const addr = await this.getCurrentAccount();
  //     const decimals = unit(
  //       await this.contractGNEDRPC.methods.decimals().call()
  //     );
  //     return await this.contractVote.methods
  //       .withDraw(BigNumber(amount).times(decimals).toFixed())
  //       .send({
  //         from: addr
  //       });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 领取分红
  // async getMoney(info, sig) {
  //   try {
  //     const addr = await this.getCurrentAccount();
  //     return await this.contractVote.methods.claimReward(info, sig).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 获取评估员信息
  // async getVoter(orderID, type) {
  //   const addr = await this.getCurrentAccount();
  //   // return this.contractVoteRpc.methods.voterInfo(addr, orderID, type).call();
  //   return this.contractVote.methods.voterInfo(addr, orderID, type).call();
  // }

  // // 查询当前保单信息
  // async getAddrVote(orderID) {
  //   // return this.contractVoteRpc.methods.voteInfo(orderID).call();
  //   return this.contractVote.methods.voteInfo(orderID).call();
  // }

  // // 投票
  // async vote(orderId, num, isAgree, isPub) {
  //   try {
  //     const addr = await this.getCurrentAccount();
  //     return await this.contractVote.methods.vote(orderId, num, isAgree, isPub).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 申诉
  // async appeal(orderId, amount) {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   return this.contract.methods
  //     .applyAgain(orderId, BigNumber(amount).times(decimals).toFixed())
  //     .send({
  //       from: addr
  //     });
  // }

  // // 理赔提现
  // async claimWithdraw(info, sig) {
  //   try {

  //     const addr = await this.getCurrentAccount();
  //     return await this.contract.methods.claimUserOrder(info, sig).send({
  //       from: addr
  //     });
  //   } catch (e) {
  //     return e
  //   }
  // }

  // // 查询买保险手续费
  // async getBuyFee() {
  //   return await this.contractCSI.methods.manageFeeRate().call();
  // }

  // // 查询申请理赔的百分比
  // async getApplyPre() {
  //   return await this.contractCSI.methods.profitFeeRate().call();
  // }

  // // 查询申诉的手续费
  // async getAppealFee() {
  //   const decimals = unit(await this.contractUSDT.methods.decimals().call());
  //   const fee = await this.contractCSI.methods.againAmount().call();
  //   return BigNumber(fee).div(decimals).toFixed();
  // }

  // // 查询专业评审可投票数
  // async getPrVotes() {
  //   const addr = await this.getCurrentAccount();
  //   // const res = await this.contractVoteRpc.methods
  //   //   .getPrAvailableVotes(addr)
  //   //   .call();
  //   const res = await this.contractVote.methods
  //     .getPrAvailableVotes(addr)
  //     .call();
  //   return {
  //     voteDec: decimalBit(BigNumber(res).div(unit(18)).toFixed(), 6),
  //     vote: res
  //   };
  // }
  // // 查询是否领取赔偿金
  // async getIsRev(id) {
  //   return await this.contractCSI.methods.isBurn(id).call();
  // }

  // // 查询是否已经申诉
  // async getIsAppeal(id) {
  //   return await this.contractCSI.methods.result(id).call();
  // }
  // // 查询最大能取回多少CPST
  // async getMax(amount) {
  //   const addr = await this.getCurrentAccount();
  //   const decimals = unit(await this.contractRPCIPST.methods.decimals().call());
  //   const res = await this.contractPoolRpc.methods
  //     .checkClaim(addr, BigNumber(amount).times(decimals).toFixed())
  //     .call();
  //   return decimalBit(BigNumber(res[1]).div(decimals).toFixed(), 6);
  // }

  // // 查询最小抵押金额
  // async getMin() {
  //   const decimals = unit(await this.contractRPCNED.methods.decimals().call());
  //   // const res = await this.contractVoteRpc.methods.minAmount().call();
  //   const res = await this.contractVote.methods.minAmount().call();
  //   return decimalBit(BigNumber(res).div(decimals).toFixed(), 6);
  // }
}

export { Contract };
