import React, { useState, useEffect } from "react";
import MenuOpen from "../../assets/img/menu_open.png";
import MenuClose from "../../assets/img/menu_close.png";
import { shortenAddress } from '../../utils'
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import { message } from 'antd'
import NFTLogoSvg from "../../assets/img/logo_m.png";
import ConnectWallet from '../ConnectWallet'
import { erc20Service } from "../../contracts";
import WalletImg from '../../assets/img/wallet.png'
import FrameImg from '../../assets/img/frame.png'
import NoFrameImg from '../../assets/img/unframe.png'
import AccountDetail from "../AccountDetail";
import "./index.scss";

const MobileNav = () => {
  const { t } = useTranslation();
  const [isMenu, setMenu] = useState(false);
  const history = useHistory();
  const below960 = useMedia("(max-width: 960px)");
  const rout = useLocation();
  const [isShadow, setShadow] = useState(false);
  const [isIDO, setIDO] = useState(true)
  const [isShowConnect, setShowConnect] = useState(false)
  const [account, setAccount] = useState("");
  const [isAccountDetail, setAccountDetail] = useState(false)
  const [showUnread, setShowUnread] = useState(true)
  const [navList, setNavList] = useState([
    {
      name: 'Tom',
      key: "tom",
      href: "tom",
      active: false
    },
    {
      name: 'Tokenomics',
      key: "tokenomics",
      href: "tokenomics",
      active: false
    },
    {
      name: "Roadmap",
      key: "roadmap",
      href: "roadmap",
      active: false
    },
    {
      name: "AI",
      key: "ai",
      href: "ai",
      active: false
    },
    {
      name: 'Mechanism',
      key: "mechanism",
      href: "mechanism",
      active: false
    },
    // {
    //   name: "NFT",
    //   key: "nft",
    //   href: "nft",
    //   active: false
    // },
    {
      name: "Referrals",
      key: "referrals",
      href: "referrals",
      active: false
    },
  ]);

  const switchMenu = show => {
    setMenu(show);
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const commsoon = () => {
    message.info(t("coming_soon"));
  };

  useEffect(() => {
    changeNavState();
    setIDO(rout.pathname == '/ido' || rout.pathname == '/referrals' || rout.pathname == '/nft')
    document.getElementById("App").onscroll = () => {
      const e = document.getElementById("App");
      const top = e.scrollTop;
      if (isShadow && top > 20) {
        return false;
      }
      if (top > 20) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };
    if (below960 && document.getElementById("marketM")) {
      const e = document.getElementById("marketM");
      const top = e.scrollTop;
      if (isShadow && top > 20) {
        return false;
      }
      if (top > 20) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    }
  }, [rout.pathname]);
  const init = async () => {

    const account1 = await erc20Service.getCurrentAccount();
    setAccount(account1);
  }

  useEffect(() => {

    const c = setInterval(() => {
      const newsStart = localStorage.getItem("newsStart")
      const newsEnd = localStorage.getItem("newsEnd")
      const newsRead = localStorage.getItem("newsRead")

      if (newsStart && newsEnd && newsRead) {
        if (newsStart == newsRead || newsEnd == newsRead) {
          setShowUnread(false)
        } else {
          setShowUnread(true)
        }
      } else {
        setShowUnread(true)
      }
    }, 1000)
    return () => {
      clearInterval(c)
    }
  }, [])


  useEffect(() => {
    init()
    var newNavList = navList.map(item => {
      return {
        icon: item.icon,
        iconActive: item.iconActive,
        href: item.href,
        name:
          item.key == 'tom' ?
            'Tom' :
            item.key == 'tokenomics' ?
              "Tokenomics" :
              item.key == 'mechanism' ?
                "Mechanism" :
              item.key == 'nft' ?
                'NFT' :
                item.key == "roadmap" ?
                  "Roadmap" :
                  item.key == "ai" ?
                    "AI" :
                    item.key == "referrals" ?
                      "Referrals" :
                        item.key == "news" ?
                          "News" :
                          "",
        key: item.key,
        active: item.active
      };
    });
    setNavList(newNavList);
  }, [localStorage.getItem("i18nextLng")]);

  const changeNavState = () => {
    const list = [...navList];
    list.forEach(item => {
      const optText = item.key;
      item.active = window.location.href.indexOf(optText) != -1 ? true : false;
    });
    setNavList(list);
  };

  const navClick = path => {
    if (path == 'tom') {
      history.push("")
    } else if (path == 'tokenomics') {
      history.push("/?section=tokenomics")
    } else if (path == 'nft') {
      history.push("/nft")
    } else if (path == 'referrals') {
      history.push("/referrals")
    } else if (path == 'roadmap') {
      history.push("/?section=roadmap")
    } else if (path == 'ai') {
      history.push("/?section=ai")
    } else if (path == 'mechanism') {
      history.push("/?section=mechanism")
    } else if (path == 'news') {
      history.push("/?section=news")
    }

    let anchorElement = document.getElementById(path);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
    changeNavState();
    setMenu(false);
  };

  const goToDom = (id) => {
    let interval = setInterval(() => {
      let anchorElement = document.getElementById(id);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
        clearInterval(interval)
      }
    }, 100);
  }
  return (
    <div
      className={["mobile_nav_box", isShadow && "mobile_nav_box_bot"].join(" ")}
    >
      {
        isShowConnect ?
          <ConnectWallet onCancle={() => setShowConnect(false)} /> : ''
      }

      {
        isAccountDetail ?
          <AccountDetail onCancle={() => {
            setAccountDetail(false)
          }} /> : ''
      }
      <div className="mobile_nav_bg">
        <div className="mobile_nav">
          <img
            src={NFTLogoSvg}
            onClick={() => navClick("home")}
            alt=""
            className="nav_logo"
          />
          {isMenu ? (
            <img
              src={MenuClose}
              alt=""
              className="nav_menu_close"
              onClick={() => switchMenu(false)}
            />
          ) : (
            <div>

                <img src={showUnread == true ? FrameImg : NoFrameImg} className="frame_img" onClick={() => navClick(`news`)} />

              <img
                src={MenuOpen}
                alt=""
                className="nav_menu"
                onClick={() => switchMenu(true)}
              />
            </div>
          )}
        </div>
      </div>
      {isMenu ? (
        <div className="menu_box">
          <div className="nav_content">
            {navList.map((item, index) => (
              <div
                className={[
                  "nav_item",
                  item.active && "nav_item_active"
                ].join(" ")}
                key={item.key}
                onClick={() => navClick(`${item.href}`)}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div className="nav_content2">
            <div className="nav_content2_create"
              onClick={
                () => {
                  return isIDO && !account ?
                    setShowConnect(true) :
                    setAccountDetail(true)
                }
              }
            >{isIDO ?

              !account ?
                <><img src={WalletImg} className="wallet_img" />Connect Wallet</> :
                <><img src={WalletImg} className="wallet_img" />{shortenAddress(account, 6)}</>
              :
              <> AITompedia</>
              }</div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MobileNav;
