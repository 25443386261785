import Web3 from "web3";
import BigNumber from "bignumber.js";

import SwapABI from "./abi/SwapToken.json";
import networkConfig from "../config";

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80
});


class SwapService {
    web3;
    nftContract;

    constructor(address) {
        try {
            if (this.web3) return;
            const web3 = window.web3;
            window.ethereum.enable();
            this.web3 = new Web3(web3.currentProvider);

            this.swapContract = new this.web3.eth.Contract(
                SwapABI,
                address
            );
            this.getCurrentAccount().then((account) => {
                this.account = account
            })
        } catch (e) {
            console.log(e.message);
        }
    }
    // 获取当前钱包地址
    async getCurrentAccount() {
        try {
            return window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(res => {
                    return res[0];
                });
        } catch (e) {
            console.log(e.message);
        }
    }

    async aiTomSwapUsdt(amount) {
        try {
            const res = await this.swapContract.methods.aiTomSwapUsdt(amount).send({ from: this.account })
            return res
        } catch (e) {
            return e
        }
    }

    async getTotalSwapUsdt() {
        try {
            const res = await this.swapContract.methods.totalSwapUsdt().call({ from: this.account })
            return res
        } catch (e) {
            console.log(e.message);
        }
    }
    async getTotalBurn() {
        try {
            const res = await this.swapContract.methods.totalBurn().call({ from: this.account })
            return res
        } catch (e) {
            console.log(e.message);
        }
    }
    async getContractBalance(token) {
        try {
            const res = await this.swapContract.methods.getContractBalance(token).call({ from: this.account })
            return res
        } catch (e) {
            console.log(e.message);
        }
    }
    async getTokenTotalSupply(token) {
        try {
            const res = await this.swapContract.methods.getTokenTotalSupply(token).call({ from: this.account })
            return res
        } catch (e) {
            console.log(e.message);
        }
    }

}

export default SwapService;
