import Web3 from "web3";
import BigNumber from "bignumber.js";

import tomSaleABI from "./abi/tomsale.json";
import networkConfig from "../config";

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80
});


class TomSaleService {
    web3;
    tomSaleContract;
    account;

    constructor() {
        try {
            if (this.web3) return;
            const web3 = window.web3;
            window.ethereum.enable();
            this.web3 = new Web3(web3.currentProvider);

            this.tomSaleContract = new this.web3.eth.Contract(
                tomSaleABI,
                networkConfig.tom_sale_addr
            );
            this.getCurrentAccount().then((account) => {
                this.account = account
            })
        } catch (e) {
            console.log(e.message);
        }
    }
    // 获取当前钱包地址
    async getCurrentAccount() {
        try {
            return window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(res => {
                    return res[0];
                });
        } catch (e) {
            console.log(e.message);
        }
    }
    // claim
    async claim() {
        try {
            const res = await this.tomSaleContract.methods.claim().send({ from: this.account })
            return res
        } catch (err) {
            return err
        }
    }
    // ico售卖相关讯息
    async getSaleInfo() {
        try {
            const result = await this.tomSaleContract.methods
                .saleInfo()
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
        }
    }

    // 池子讯息，ico开始后使用
    async getInProgressInfo() {
        const respond = {
            totalActualAmount: 0,
            totalFaceValue: 0,
            totalSaleAmount: 0,
            perAmount: 0,
            remainAmount: 0,
            state: 0,
            isSet: false,
            started: false,
            ended: false,
            perMinAmount: 0,
            maxAmount: 0
        }
        try {
            const saleInfo = await this.getSaleInfo()
            const result = await this.tomSaleContract.methods
                .poolInfo()
                .call({ from: this.account });
            respond.totalActualAmount = result.totalActualAmount
            respond.totalFaceValue = result.totalFaceValue
            respond.totalSaleAmount = result.totalSaleAmount
            respond.perAmount = result.perAmount
            respond.state = result.state
            respond.isSet = result.isSet
            respond.perMinAmount = saleInfo.perMinAmount
            respond.maxAmount = saleInfo.maxAmount

            if (saleInfo.startTime < Math.floor(Date.now() / 1000)) {
                respond.started = true
            }
            if (saleInfo.endTime < Math.floor(Date.now() / 1000)) {
                respond.ended = true
            }

            return respond;
        } catch (e) {
            console.log(e.message);
        }
    }

    // 用户购买讯息
    async getPurchaseProfile() {
        try {
            const result = await this.tomSaleContract.methods
                .userInfo(this.account)
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
            return {}
        }
    }

    //用户释放讯息
    async getUnlockAmount() {
        try {
            const result = await this.tomSaleContract.methods
                .getETHAndUnLockAmount(this.account)
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
            return []
        }
    }

    // 获得2次解锁时间
    async getNextUnlockTime() {
        try {
            const result = await this.tomSaleContract.methods
                .getTime()
                .call({ from: this.account });
            if (result.sTime > Math.floor(Date.now() / 1000)) {
                return result.sTime
            }
            return result.tTime;
        } catch (e) {
            console.log(e.message);
        }
    }

    // 
    async buyAITom(amount, nft, tokenID, discount) {
        try {
            const ethers = Web3.utils.toWei((amount * (1 - discount)).toString(), 'wei')
            console.log(amount, nft, tokenID, ethers)
            const result = await this.tomSaleContract.methods
                .deposit(amount, nft, tokenID)
                .send({ from: this.account, value: ethers });
            return result;
        } catch (e) {
            console.log(e.message);
            return e
        }
    }


    async getEstimateTomAmount() {
        const acc = await this.getCurrentAccount()
        try {
            const result = await this.tomSaleContract.methods
                .getEstimateTomAmount(acc)
                .call({ from: acc });
            return result;
        } catch (e) {
            console.log(e.message);
        }
    }

    async isDepositor() {
        try {
            const result = await this.tomSaleContract.methods
                .getDepositorIn(this.account)
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
        }
    }

    async getNftDiscount(nft) {
        try {
            const result = await this.tomSaleContract.methods
                .getDiscount(nft, this.account)
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
        }
    }

    async checkCalim() {
        try {
            const result = await this.tomSaleContract.methods
                .checkCalim(this.account)
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
            return { 0: 0, 1: 0 }
        }
    }
}

export default TomSaleService;
