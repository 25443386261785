import Web3 from "web3";
import BigNumber from "bignumber.js";

import tomMarketABI from "./abi/tommarket.json";
import networkConfig from "../config";

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80
});


class TomMarketService {
    web3;
    tomMarketContract;
    account;

    constructor() {
        try {
            if (this.web3) return;
            const web3 = window.web3;
            window.ethereum.enable();
            this.web3 = new Web3(web3.currentProvider);

            this.tomMarketContract = new this.web3.eth.Contract(
                tomMarketABI,
                networkConfig.tom_market_addr
            );
            this.getCurrentAccount().then((account) => {
                this.account = account
            })
        } catch (e) {
            console.log(e.message);
        }
    }
    // 获取当前钱包地址
    async getCurrentAccount() {
        try {
            return window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(res => {
                    return res[0];
                });
        } catch (e) {
            console.log(e.message);
        }
    }

    // getIsInBuyerWhitelist
    async getIsInBuyerWhitelist() {
        try {
            const result = await this.tomMarketContract.methods
                .getIsInBuyerWhitelist(this.account)
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
        }
    }

    // 
    async checkPurchase() {
        try {
            const result = await this.tomMarketContract.methods
                .checkPurchase(this.account)
                .call({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
        }
    }

    async buyAITom() {
        try {
            const result = await this.tomMarketContract.methods
                .purchase()
                .send({ from: this.account });
            return result;
        } catch (e) {
            console.log(e.message);
            return e
        }
    }
}

export default TomMarketService;
