import { SET_LOGIN } from "./action";

const loginState = {
  token: "",
  id: "",
  address: "",
  email: "",
  isLogin: false
};

const login = (state = loginState, action) => {
  if (action.type === SET_LOGIN.type) {
    return action.payload;
  } else {
    return state;
  }
};

export default login;
