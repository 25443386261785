import React, { useState, useEffect } from "react";
import Close from "../../assets/img/modal_close.png";
import FoxImg from "../../assets/img/fox.png";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import config from "../../config";
import { erc20Service } from "../../contracts";
import { useWeb3React } from "@web3-react/core";
import { LoginSign } from "../../api";
import "./index.scss";

const ConnectWallet = props => {
  const rout = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { onCancle } = props
  const { activate } = useWeb3React()

  const link = async () => {

    // if (!(window.web3 || window.ethereum)) {
    //   window.open("https://metamask.io/");
    //   onCancle()
    //   return;
    // }
    const account1 = await erc20Service.getCurrentAccount();
    await activate(config.injected)
    LoginSign({
      address:account1,
    })
    onCancle()
  }
  return (
    <div className={["connect_box"].join(' ')}>
      <div className="connect_mask" />
      <div className="connect_content">
        <img src={Close} onClick={onCancle} className="connect_close" />
        <div className="connect_title">Connect wallet</div>
        <div className="connect_text">Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrasesecurely. Never share them with anyone.</div>
        <div className="connect_btn" onClick={link}><img src={FoxImg} className="connect_fox" />Metamask</div>
      </div>
    </div>
  );
};

export default ConnectWallet;
