import axios from "../axios";

class HttpApi {
  // 获取登录随机数
  async getUserNonce(params) {
    return axios.get("/client/v1/users/authentication", params);
  }
  // 登录校验
  async checkUserLogin(params) {
    return axios.post("/client/v1/users/authentication", params);
  }

  // 首页数据
  async getHomeData() {
    return axios.get("/client/v1/index/showindex");
  }

  // 市场分类列表
  async getMarketCategory() {
    return axios.get("/client/v1/market/shownfttypes");
  }

  // 市场数据
  async getMarketData(params) {
    return axios.get("/client/v1/market/showmarket", params);
  }

  // 我的
  async getMyData(params) {
    return axios.get("/client/v1/users/my", params);
  }

  // 个人信息
  async getMyInfo() {
    return axios.get("/client/v1/users");
  }

  // 修改用户信息
  async setMyInfo(params) {
    return axios.put("/client/v1/users", params);
  }
  // NFT详情
  async getNFTDetail(params) {
    return axios.get("/client/v1/nft", params);
  }

  // 获取随机盐
  async getSalt() {
    return axios.get("/client/v1/nft/sale/salt");
  }

  // 上传图片
  async uploadImg(params) {
    return axios.post("/client/v1/users/image", params);
  }

  // 上传图片
  async uploadVideo(params) {
    return axios.post("/client/v1/users/video", params);
  }

  // 判断NFT是否是在出售状态(登陆)
  async isNftSell(params) {
    return axios.get("/client/v1/nft/status", params);
  }
  // 创建NFT
  async createNft(params) {
    return axios.post("/client/v1/nft", params);
  }

  // 保存订单信息
  async seveOrderInfo(params) {
    return axios.post("/client/v1/nft/sale/hx", params);
  }

  // 获取订单数据
  async getOrderInfo(params) {
    return axios.get("/client/v1/nft/sale/hx", params);
  }

  // 获取服务器当前时间
  async getServerTime() {
    return axios.get("/client/v1/nft/time");
  }

  // 编辑NFT
  async editNft(params) {
    return axios.put("/client/v1/nft", params);
  }

  // 出售NFT
  async sellNft(params) {
    return axios.post("/client/v1/nft/sale", params);
  }

  // 取消出售NFT
  async sellNftCancle(params) {
    return axios.post("/client/v1/nft/sale/cancel", params);
  }

  // 购买NFT
  async buyNft(params) {
    return axios.post("/client/v1/nft/buy", params);
  }

  // 注册
  async register(params) {
    return axios.post("/client/v1/users/email", params);
  }

  // 登录
  async login(params) {
    return axios.post("/client/v1/users/login", params);
  }

  // 修改密码
  async editPassword(params) {
    return axios.post("/client/v1/users/update", params);
  }

  // 重置密码发送邮件
  async resetPasswordEmail(params) {
    return axios.post("/client/v1/users/forget", params);
  }

  // 重置密码
  async resetPassword(params) {
    return axios.post("/client/v1/users/forget/verify", params);
  }

  // 币种list
  async coinList() {
    return axios.get("/client/v1/nft/coin");
  }
}

// 获取nft
export const getNFTInStock = async (data) => {
  // return axios.get("/client/nei/buy", data);
  return axios.get("/client/nft/instock", data);
}

// 获取ico merkle reward
export const getIDOReward = async (data) => {
  return axios.get("/client/ido_reward/get_user_records", data);
}
// 获取ico merkle reward
export const getIDOReward2 = async (data) => {
  return axios.get("/client/ido_reward/get_deposit_records", data);
}

// 邀请关系 建立获取
export const createInviteUserRelationship = async (data) => {
  // create_type = 1, 获得invite code
  return axios.post("/client/invite_return/create_user_relationship", data);
}

// 获取用户返佣
export const getInviteReturnUsers = async (data) => {
  return axios.get("/client/invite_return/get_user_records", data);
}
// 获取用户Token列表
export const getUserTokenList = async (data) => {
  return axios.get("/client/get_user_token_list", data);
}
// 登陆签名
export const LoginSign = async (data) => {
  // {
  //   address
  // }
  return axios.post("/client/enroll", data);
}


// 获取News列表
export const getNewsList = async (data) => {
  return axios.get("/client/updates/get_updates", data);
}
// 获取Burn列表
export const getBurning = async (data) => {
  return axios.get("/client/burning", data);
}



const httpApi = new HttpApi();
export default httpApi;
