import { combineReducers } from "redux";
import user from "./user/reducer";
import login from "./login/reducer";
import nav from "./nav/reducer";
import network from "./network/reducer";

const appReducer = combineReducers({
  user,
  login,
  nav,
  network
});

export default appReducer;
