import Web3 from "web3";
import BigNumber from "bignumber.js";

import AiTomTokenABI from "./abi/AiTomToken.json";
import networkConfig from "../config";

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80
});


class AITomService {
    web3;
    nftMarketContract;
    account;

    constructor() {
        try {
            if (this.web3) return;
            const web3 = window.web3;
            window.ethereum.enable();
            this.web3 = new Web3(web3.currentProvider);

            this.aiTomContract = new this.web3.eth.Contract(
                AiTomTokenABI,
                networkConfig.aitom_token_addr
            );
            this.getCurrentAccount().then((account) => {
                this.account = account
            })
        } catch (e) {
            console.log(e.message);
        }
    }
    // 获取当前钱包地址
    async getCurrentAccount() {
        try {
            return window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(res => {
                    return res[0];
                });
        } catch (e) {
            console.log(e.message);
        }
    }


    async getAllowance() {
        try {
            let account = await this.getCurrentAccount();
            const res = await this.aiTomContract.methods.allowance(account, networkConfig.swap_token).call({ from: account })
            return res;
        } catch (e) {
            console.log(e.message);
        }
    }

    async approve() {
        try {
            let account = await this.getCurrentAccount();
            const approveValue = this.web3.utils.toWei("100000000000000000", "ether");
            const res = await this.aiTomContract.methods.approve(networkConfig.swap_token, approveValue).send({ from: account })
            return res;
        } catch (e) {
            return e
        }
    }

    async getAitomBalance() {
        try {
            let account = await this.getCurrentAccount();
            const res = await this.aiTomContract.methods.balanceOf(account).call({ from: account })
            return res;
        } catch (e) {
            console.log(e.message);
        }
    }

    async getUsdtBalance() {
        try {
            let account = await this.getCurrentAccount();
            const con = new this.web3.eth.Contract(
                AiTomTokenABI,
                networkConfig.usdt_addr
            );
            const res = await con.methods.balanceOf(account).call({ from: account })
            return res;
        } catch (e) {
            console.log(e.message);
        }
    }

    async getTotalSupply() {
        try {
            let account = await this.getCurrentAccount();
            const res = await this.aiTomContract.methods.totalSupply().call({ from: account })
            return res;
        } catch (e) {
            console.log(e.message);
        }
    }


}

export default AITomService;
