import "./App.scss";
import Nav from "../src/components/Nav";
import Footer from "../src/components/Footer";
import MobileNav from "../src/components/MobileNav";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { LoginSign } from "./api";
import { useMedia } from "react-use";
import { useWeb3React } from "@web3-react/core";
import { SWITCH_NETWORK } from "./store/network/action";
import { useSelector, useDispatch } from "react-redux";
import SwitchNetworkModal from '../src/components/SwitchNetworkModal'
import config from "./config";

import loadable from "./utils/loadable";
const Home = loadable(() => import("./pages/home"));
const Ido = loadable(() => import("./pages/ido"));
const Referrals = loadable(() => import("./pages/referrals"));
const Nft = loadable(() => import("./pages/nft"));
const Community = loadable(() => import("./pages/community"));

const App = () => {
  const below960 = useMedia("(max-width: 960px)");
  const { deactivate } = useWeb3React()
  const dispatch = useDispatch();


  const loginSign = async (accounts) => {
    const exampleMessage = 'Example `personal_sign` message.';
    try {
      const from = accounts;
      // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
      // This uses a Node.js-style buffer shim in the browser.
      const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
      const sign = await window.ethereum.request({
        method: 'personal_sign',
        params: [msg, from, 'Example password'],
      });
      if (sign) {
        const apiSign = await LoginSign({
          msg, sign, address: from
        })
        if (apiSign.code == 0) {

        } else {
          deactivate()
        }
      }
    } catch (err) {
      deactivate()
    }
  }

  const checkNetwork = network => {
    const supportedChainIds = config.supportedChainIds; // 当前支持的网络
    if (config.networkId != network) {
      // if (supportedChainIds.indexOf(Number(network)) === -1) {
      dispatch({ type: SWITCH_NETWORK.type, payload: { switchNetwork: true } });
    }
  };
  const getNetwork = async () => {
    if (window.ethereum) {
      const network = await window.ethereum.request({
        method: "eth_chainId"
      });
      checkNetwork(network);
    }
  };

  useEffect(() => {
    if (window.ethereum) {


      // 监听网络变化
      window.ethereum.on("networkChanged", network => {
        window.location.reload();
      });

      getNetwork();
      // 切换账号刷新
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload();
        // loginSign(accounts[0])
      })
    }
  }, [])

  const openSwitchNetworkModal = useSelector(state => state.network);
  return (
    <Suspense fallback={<div>loading....</div>}>
      <BrowserRouter>

        {openSwitchNetworkModal.switchNetwork && (
          <SwitchNetworkModal
            visible={openSwitchNetworkModal.switchNetwork}
            onCancle={() =>
              dispatch({
                type: SWITCH_NETWORK.type,
                payload: { switchNetwork: false }
              })
            }
          />
        )}
        <div className="App" id="App">
          {below960 ? (
            <>
              <MobileNav />
            </>
          ) : (
            <>
              <Nav />
            </>
          )}

          <div className="content" id="content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/ido" component={Ido} />
              <Route exact path="/community" component={Community} />
              <Route exact path="/referrals" component={Referrals} />
              <Route exact path="/i" component={Referrals} />
              {/* <Route exact path="/nft" component={Nft} /> */}
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
