import React, { useState, useEffect } from "react";
import FooterLogo from "../../assets/img/footer_logo.png";
import FooterLogoM from "../../assets/img/footer_logo_m.png";
import FooterDis from "../../assets/img/footer_discord.png";
import FooterTele from "../../assets/img/footer_tele.png";
import FooterTwi from "../../assets/img/footer_twitter.png";
import FooterCMC from "../../assets/img/footer_cmc.png";
import FooterCoingeco from "../../assets/img/footer_coingeco.png";
import FooterAudit from "../../assets/img/footer_audit.png";
import FooterContact from "../../assets/img/footer_contact.png";

import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import "./index.scss";

const Footer = props => {
  const rout = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const below960 = useMedia("(max-width: 960px)");
  const [isIDO, setIDO] = useState(false)

  useEffect(() => {
    // 控制某些页面隐藏footer
    // setIDO(rout.pathname == '/ido' || rout.pathname == '/referrals' || rout.pathname == '/nft')
  }, [rout.pathname]);
  const navGoTo = h => {
    history.push(h);
  };

  const footerClick = (id) => {
    history.push(`/home`);

    let interval = setInterval(() => {

      let anchorElement = document.getElementById(id);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
        clearInterval(interval)
      }
    }, 100);
  }

  return (
    <div className={["footer_box", isIDO ? 'hide_footer' : ''].join(' ')}>
      <div className="footer_box2">

        <div className="footer_content">
          <div className="footer_content_left">
            <img src={below960 ? FooterLogoM : FooterLogo} className="footer_content_left_logo" />
            <div className="footer_content_left_text1">Be Our Community Builder</div>
            <div className="footer_content_left_text2">
              Let's Cheer for the
              <br />
              Great AI Revolution
            </div>
          </div>
          <div className="footer_content_right">
            <div className="footer_right_medium">
              {/* <img src={FooterDis} /> */}
              <div className="display_row">
                <img src={FooterTele} onClick={() => window.open("https://t.me/AITomPro")} />
                <img src={FooterTwi} onClick={() => window.open("https://twitter.com/MemeAitom")} />
                <a href="mailto:support@aitom.pro"><img src={FooterContact} /></a>
              </div>
              <div className="display_row">
                <img src={FooterCoingeco} onClick={() => window.open("https://coingecko.com")} />
                <img src={FooterCMC} onClick={() => window.open("https://coinmarketcap.com/")} />
                <img src={FooterAudit} onClick={() => window.open("https://github.com/CFG-NINJA/audits/blob/3c762e5575d61e6d2bed9f7205d5c5d323f894f5/20230716_CFGNINJA_AITom_AITom_Audit.pdf")} />
              </div>
              {/* <img src={FooterMed} /> */}
            </div>

            <div className="footer_right_text">
              © 2023 AITom All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
