import Web3 from "web3";
import BigNumber from "bignumber.js";

import nftABI from "./abi/nft721token.json";
import networkConfig from "../config";

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80
});


class NFTService {
    web3;
    nftContract;

    constructor(address) {
        try {
            if (this.web3) return;
            const web3 = window.web3;
            window.ethereum.enable();
            this.web3 = new Web3(web3.currentProvider);

            this.nftContract = new this.web3.eth.Contract(
                nftABI,
                address
            );
        } catch (e) {
            console.log(e.message);
        }
    }
    // 获取当前钱包地址
    async getCurrentAccount() {
        try {
            return window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(res => {
                    return res[0];
                });
        } catch (e) {
            console.log(e.message);
        }
    }
    
    async listenMintEvent(block, txhash){
        
        return await this.nftContract.getPastEvents("MintTo", { filter: {}, fromBlock: block, toBlock: block })
    }

    async  getCurrentSequence(){
        try {
            const res = await this.nftContract.methods.getCurrentID().call({ from: this.account })
            console.log("res", res)
            return res
        } catch (e) {
            console.log(e.message);
        }
    }
}

export default NFTService;
