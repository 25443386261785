import { SET_USER_INFO } from "./action";

const userState = {};

const user = (state = userState, action) => {
  if (action.type === SET_USER_INFO.type) {
    return action.payload;
  } else {
    return state;
  }
};

export default user;
