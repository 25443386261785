import React, { useState, useEffect } from "react";
import Close from "../../assets/img/modal_close.png";
import WalletCopy from "../../assets/img/wallet_copy.png";
import WalletView from "../../assets/img/wallet_view.png";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { erc20Service } from "../../contracts";
import { useWeb3React } from "@web3-react/core";
import { shortenAddress } from '../../utils'
import copy from "copy-to-clipboard";
import config from "../../config";
import { showTip } from "../common/CMessageTip";
import "./index.scss";

const AccountDetail = props => {
  const rout = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { onCancle } = props
  const { activate, deactivate } = useWeb3React()
  const [account, setAccount] = useState('')

  const init = async () => {
    const account1 = await erc20Service.getCurrentAccount();
    setAccount(account1);
  }

  const copyText = (text) => {
    copy(text)
    showTip(
      "copyText",
      "success",
      'Copied',
      ''
    )
  }

  const logOut = async () => {
    await deactivate()
    showTip(
      "copyText",
      "success",
      'Successfully logged out',
      ''
    )
    onCancle()
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <div className={["accountDetail_box"].join(' ')}>
      <div className="accountDetail_mask" />
      <div className="accountDetail_content">
        <img src={Close} onClick={onCancle} className="accountDetail_close" />
        <div className="accountDetail_title">Account</div>
        <div className="accountDetail_data">
          <div className="accountDetail_data_line">
            Connecting to MetaMask
            {/* <div className="accountDetail_data_logout_btn" onClick={logOut}>log out</div> */}
          </div>
          <div className="accountDetail_data_addr">{shortenAddress(account, 6)}</div>
          <div className="accountDetail_data_line2">
            <div className="accountDetail_data_line2_item" onClick={() => copyText(account)}>
              <img src={WalletCopy} className="accountDetail_data_line2_item_copy_img" />
              Copy address
            </div>
            <div className="accountDetail_data_line2_item" onClick={() => window.open(config.explorer + "/" + account)}>
              <img src={WalletView} className="accountDetail_data_line2_item_view_img" />
              View on Explorer
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetail;
