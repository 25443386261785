import Web3 from "web3";
import BigNumber from "bignumber.js";

import nftMarketABI from "./abi/NFTMarket.json";
import networkConfig from "../config";

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80
});


class NFTMarketService {
    web3;
    nftMarketContract;
    account;

    constructor() {
        try {
            if (this.web3) return;
            const web3 = window.web3;
            window.ethereum.enable();
            this.web3 = new Web3(web3.currentProvider);

            this.nftMarketContract = new this.web3.eth.Contract(
                nftMarketABI,
                networkConfig.nft_market_addr
            );
            this.getCurrentAccount().then((account) => {
                this.account = account
            })
        } catch (e) {
            console.log(e.message);
        }
    }
    // 获取当前钱包地址
    async getCurrentAccount() {
        try {
            return window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(res => {
                    return res[0];
                });
        } catch (e) {
            console.log(e.message);
        }
    }
    // 购买nft的方法
    // 等待完成，获得 nft id 再modal展示图片
    async purchaseNFT(nft) {
        try {
            const res = await this.nftMarketContract.methods.purchaseNFT(this.account, nft).send({ from: this.account })
            return res
        } catch (err) {
            return err
        }
    }

    // 空头nft的方法
    async airdropNFT(nft) {
        try {
            const res = await this.nftMarketContract.methods.airDropNft(nft, [this.account]).send({ from: this.account })
            return res
        } catch (err) {
            return err
        }
    }

}

export default NFTMarketService;
