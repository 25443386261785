// 线上环境配置
export default {
  networkId: 42161, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://api.aitom.pro",
  homeUrl: "https://aitom.pro",

  inviteUrl: "https://aitom.pro/",
  icoUrl: "https://aitom.pro/ido",
  nftUrl: "https://aitom.pro/nft",
  explorer: "https://arbiscan.io/address",
  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'ARB',

  ethRpc: "https://arbitrum.meowrpc.com/",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：ETH

  address0: "0x0000000000000000000000000000000000000000",

  usdt_addr: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  aitom_token_addr: "0x9578A9937dFf45b4E29e49120AB83cb806f1aa4F",
  referral_merkle_distributor_addr: "0xc4d204b5349364bbb8eb0febe4b0c5e234fbd5b3",
  ico_reward_distributor_addr: "0xcE757917147d00828C02B93B5b34be9F7F32C57e",
  tom_sale_addr: "0x8363d3e597ac84c11f5dc19b2cda49372bb41dc0",
  nft1_addr: "0x8fEB081B70ef05dC51C98deb1830C19e3B7E2Ddb",
  nft2_addr: "0x61236f6ff345069799A5c1a8f81E8eF97Eb61544",
  nft3_addr: "0x3fA7ca9c504fEa75f5F0FeAa685370C687632878",
  tom_market_addr: "0x16d07490a61e6807ae7efa04eee3422efd1075da",
  nft_market_addr: "0xf4d6e37733ce70fc1e914c3970690a1873b80a25",
  swap_token: "0x3c8aaa72d71f9c2bbfe04ad5f1b21851a761d425",
  miningUrl: "https://aitom.pro/mining",

};
