export default {
  // Eth 正式
  1: {
    chainId: "0x1",
    chainName: "以太坊 Ethereum 主网络",
    nativeCurrency: {
      name: "ETH Token",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://mainnet.infura.io/v3/undefined"],
    blockExplorerUrls: ["https://etherscan.io"]
  },
  3: {
    chainId: "0x3",
    chainName: "Ropsten测试网络",
    nativeCurrency: {
      name: "ETH Token",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://ropsten.etherscan.io"]
  },

  56: {
    chainId: "0x38",
    chainName: "BSC",
    nativeCurrency: {
      name: "BNB Token",
      symbol: "BNB",
      decimals: 18
    },
    rpcUrls: ["https://bsc-dataseed1.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com/"]
  },

  65: {
    chainId: "0x41",
    chainName: "OKC测试网",
    nativeCurrency: {
      name: "OKT Token",
      symbol: "OKT",
      decimals: 18
    },
    rpcUrls: ["https://exchaintestrpc.okex.org"],
    // blockExplorerUrls: ["https://testnet.hecoinfo.com/"]
  },
  97: {
    chainId: "0x61",
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BNB Token",
      symbol: "tBNB",
      decimals: 18
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"]
  },

  128: {
    chainId: "0x80",
    chainName: "HECO",
    nativeCurrency: {
      name: "HT Token",
      symbol: "HT",
      decimals: 18
    },
    rpcUrls: ["https://http-mainnet-node.huobichain.com"],
    blockExplorerUrls: ["https://scan.hecochain.com"]
  },
  256: {
    chainId: "0x100",
    chainName: "HECO测试网",
    nativeCurrency: {
      name: "HT Token",
      symbol: "HT",
      decimals: 18
    },
    rpcUrls: ["https://http-testnet.hecochain.com"],
    blockExplorerUrls: ["https://testnet.hecoinfo.com/"]
  },
  42161: {
    chainId: "0xA4B1",
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "ETH Token",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io"]
  }
};
