import { SET_UPDATE } from "./action";

const navState = { update: 0 };

const nav = (state = navState, action) => {
  if (action.type === SET_UPDATE.type) {
    return action.payload;
  } else {
    return state;
  }
};

export default nav;
