import { SWITCH_NETWORK } from "./action";

const networkState = {
  switchNetwork: false,
};

const network = (state = networkState, action) => {
  if (action.type === SWITCH_NETWORK.type) {
    return action.payload;
  } else {
    return state;
  }
};

export default network;
