import React, { useState, useEffect } from "react";
import TomLogo from "../../assets/img/logo.png";
import config from "../../config"
import { message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";
import ConnectWallet from '../ConnectWallet'
import { erc20Service } from "../../contracts";
import { useWeb3React } from "@web3-react/core";
import { shortenAddress } from '../../utils'
import AccountDetail from "../AccountDetail";
import WalletImg from '../../assets/img/wallet.png'
import FrameImg from '../../assets/img/frame.png'
import NoFrameImg from '../../assets/img/unframe.png'
import "./index.scss";

const Nav = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isShadow, setShadow] = useState(false);
  const rout = useLocation();
  const below960 = useMedia("(max-width: 960px)");
  const { active } = useWeb3React();
  const [activeNav, setActiveNav] = useState(false);
  const [flag, setFlag] = useState('home')
  const [account, setAccount] = useState("");
  const [isNotHome, setIsNotHome] = useState(true)
  const [isShowConnect, setShowConnect] = useState(false)
  const [isAccountDetail, setAccountDetail] = useState(false)
  const [showUnread, setShowUnread] = useState(true)

  const [navList, setNavList] = useState([
    // {
    //   name: 'Tom',
    //   key: "tom",
    //   href: "tom",
    //   active: false
    // },
    {
      name: 'Tokenomics',
      key: "tokenomics",
      href: "tokenomics",
      active: false
    },
    {
      name: "Roadmap",
      key: "roadmap",
      href: "roadmap",
      active: false
    },
    {
      name: "AI",
      key: "ai",
      href: "ai",
      active: false
    },
    {
      name: 'Mechanism',
      key: "mechanism",
      href: "mechanism",
      active: false
    },
    // {
    //   name: "NFT",
    //   key: "nft",
    //   href: "nft",
    //   active: false
    // },
    // {
    //   name: "Referrals",
    //   key: "referrals",
    //   href: "referrals",
    //   active: false
    // },
    {
      name: "Mining",
      key: "mining",
      href: "mining",
      active: false
    },
  ]);


  const init = async () => {
    const account1 = await erc20Service.getCurrentAccount();
    setAccount(account1);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const posi = searchParams.get('posi');

    if (posi) {
      const data = {
        tokenomics: 'tokenomics',
        roadmap: 'roadmap',
        ai: 'ai',
        mechanism: 'mechanism',
      }
      goToDom(data[posi])
    }

    const c = setInterval(() => {
      const newsStart = localStorage.getItem("newsStart")
      const newsEnd = localStorage.getItem("newsEnd")
      const newsRead = localStorage.getItem("newsRead")

      if (newsStart && newsEnd && newsRead) {
        if (newsStart == newsRead || newsEnd == newsRead) {
          setShowUnread(false)
        } else {
          setShowUnread(true)
        }
      } else {
        setShowUnread(true)
      }
    }, 1000)
    return () => {
      clearInterval(c)
    }
  }, [])

  useEffect(() => {
    init()
    var newNavList = navList.map(item => {
      return {
        icon: item.icon,
        iconActive: item.iconActive,

        href: item.href,
        name:
          item.key == 'tom' ?
            'Tom' :
            item.key == 'tokenomics' ?
              "Tokenomics" :
              item.key == 'mechanism' ?
                "Mechanism" :
                item.key == 'nft' ?
                  'NFT' :
                  item.key == "roadmap" ?
                    "Roadmap" :
                    item.key == "ai" ?
                      "AI" :
                      item.key == "referrals" ?
                        "Referrals" :
                        item.key == "mining" ?
                          "Mining" :
                          item.key == "news" ?
                            "News" :
                            "",
        key: item.key,
        active: item.active
      };
    });
    setNavList(newNavList);
  }, [localStorage.getItem("i18nextLng")]);

  useEffect(() => {
    changeNavState();
    setIsNotHome(rout.pathname == '/')
    document.getElementById("App").onscroll = () => {
      const e = document.getElementById("App");
      const top = e.scrollTop;
      // if (isShadow && top > 20) {
      //   return false;
      // }
      if (top > 20) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };
    if (below960 && document.getElementById("marketM")) {
      const e = document.getElementById("marketM");
      const top = e.scrollTop;
      if (isShadow && top > 20) {
        return false;
      }
      if (top > 20) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    }
  }, [rout.pathname]);

  const changeNavState = () => {
    const list = [...navList];
    list.forEach(item => {
      const optText = item.key;
      // item.active = window.location.href.indexOf(optText) != -1 ? true : false;
      item.active = item.key == flag ? true : false;
    });

    setNavList(list);

    setActiveNav(
      window.location.href.indexOf("/productTutorial") != -1 ? true : false
    );
  };
  useEffect(() => {
    if (flag == 'GitHub') {
      window.open('https://github.com/ISMfinance')
      return
    }
    if (flag == 'gotov1') {
      window.open('https://ins.ism.finance/#/home')
      return
    }
    // history.push(`/home`);
    changeNavState();
    // if (path == 'home') {
    if (flag == 'dfdf') {
      history.push(`/${flag}`);
      changeNavState();
    } else {
      let interval = setInterval(() => {
        let anchorElement = document.getElementById(flag);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
          clearInterval(interval)
        }
      }, 100);
    }
  }, [flag])

  const goToDom = (id) => {
    let interval = setInterval(() => {
      let anchorElement = document.getElementById(id);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
        clearInterval(interval)
      }
    }, 100);
  }

  const navClick = path => {
    if (path == 'tom') {
      history.push("")
    } else if (path == 'tokenomics') {
      history.push("/?section=tokenomics")
    } else if (path == 'nft') {
      history.push("/nft")
    } else if (path == 'roadmap') {
      history.push("/?section=roadmap")
    } else if (path == 'referrals') {
      history.push("/referrals")
    } else if (path == 'ai') {
      history.push("/?section=ai")
    } else if (path == 'mining') {
      // history.push("/?section=mining")
      window.location.href = config.miningUrl
    } else if (path == 'mechanism') {
      history.push("/?section=mechanism")
    } else if (path == 'news') {
      history.push("/?section=news")
    }
    setFlag(path)
  };
  return (
    <div className={["nav_b", isShadow && "nav_b_bot"].join(" ")}>
      {
        isShowConnect ?
          <ConnectWallet onCancle={() => {
            setShowConnect(false)
          }
          } /> : ''
      }

      {
        isAccountDetail ?
          <AccountDetail onCancle={() => {
            setAccountDetail(false)
          }} /> : ''
      }
      <div className="nav_box">
        <div className="nav_box_left">
          <img
            src={TomLogo}
            alt=""
            onClick={() => navClick("home")}
            className="nav_logo"
          />
          <div className="nav_content">
            {navList.map((item, index) => (
              <div
                className={["nav_item", item.active && "nav_item_active"].join(
                  " "
                )}
                key={item.key}
                onClick={() => navClick(`${item.href}`)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <img src={showUnread ? FrameImg : NoFrameImg} className="frame_img" onClick={() => navClick(`news`)} />
        {
          !isNotHome && <div className="nav_right">
            <div className="nav_right_create"
              onClick={
                () => {
                  if (isNotHome) {
                    return
                  }
                  return isNotHome && !account ?
                    setShowConnect(true) :
                    setAccountDetail(true)
                }
              }
            >{!isNotHome ?
              !account ?
                <><img src={WalletImg} className="wallet_img" />Connect Wallet</> :
                <><img src={WalletImg} className="wallet_img" />{shortenAddress(account, 6)}</>
              :
              <> AITompedia</>
              }</div>
          </div>
        }
      </div>
    </div>
  );
};

export default Nav;
