import React, { useState, useEffect } from "react";
import Close from "../../assets/img/modal_close.png";
import FoxImg from "../../assets/img/fox.png";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import config from "../../config";
import chainsConfig from '../../config/chainsConfig'
import { useWeb3React } from "@web3-react/core";
import { LoginSign } from "../../api";
import "./index.scss";

const SwitchNetworkModal = props => {
  const rout = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { onCancle } = props
  const { activate } = useWeb3React()


  const switchNet = value => {
    try {
      if (!chainsConfig[value]) {
        alert("No network id found from config")
        return
      }
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [chainsConfig[value]]
        })
        .then(() => {
          // onCancel();
        });
    } catch (err) {
    }
  };
  return (
    <div className={["switch_net_box"].join(' ')}>
      <div className="switch_net_mask" />
      <div className="switch_net_content">
        <img src={Close} onClick={onCancle} className="switch_net_close" />
        <div className="switch_net_title">Network Switching Prompt</div>
        <div className="switch_net_text">The network used by Marquee is the {config.chainName} network. Do you want to switch now?</div>
        <div className="switch_net_btn" onClick={() => switchNet(config.networkId)}>Switch to {config.chainName} Network</div>
      </div>
    </div>
  );
};

export default SwitchNetworkModal;
