import { Contract } from "./contract";
import Erc20Service from "./Erc20Service";
import TomSaleService from "./TomSaleService";
import TomMarketService from "./TomMarketService";
import NFTService from "./NFTService"
import NFTMarketService from "./NFTMarketService"
import SwapService from "./SwapService"
import AITomService from "./AITomService"
import MerkleDistributor from './MerkleDistributor'
import config from "../config";

export const ContractServe = new Contract();
export const erc20Service = new Erc20Service();
export const tomSaleService = new TomSaleService();
export const tomMarketService = new TomMarketService()
export const nft1Service = new NFTService(config.nft1_addr);
export const nft2Service = new NFTService(config.nft2_addr);
export const nft3Service = new NFTService(config.nft3_addr);
export const nftMatketService = new NFTMarketService();

export const inviteMerkleDistributorbutor = new MerkleDistributor(config.referral_merkle_distributor_addr);
export const icoMerkleDistributorbutor = new MerkleDistributor(config.ico_reward_distributor_addr);
export const swapService = new SwapService(config.swap_token);
export const aiTomService = new AITomService(config.aitom_token_addr);

