import Web3 from "web3";
import BigNumber from "bignumber.js";
import { noWallet, unit, nToBN } from "../utils/index";
import config from "../config";
import DistributorABI from './abi/MerkleDistributor.json'
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});


class MerkleDistributor {
  web3;
  constructor(contractAddress) {
    try {
      const web3 = window.web3;
      window.ethereum.enable();
      this.currentWeb3 = new Web3(web3.currentProvider);

      this.inviteContract = new this.currentWeb3.eth.Contract(DistributorABI, contractAddress);
    } catch (e) {
      console.log(e.message);
    }
  }

  // 获取当前钱包地址
  async getCurrentAccount() {
    try {
      return window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(res => {
          // console.log(res);
          return res[0];
        });
    } catch (e) {
      console.log(e.message);
    }
  }

  // 获取当前钱包地址2
  async getCurrentAccount2() {
    try {
      let account = ''
      await this.currentWeb3.eth.getAccounts().then(res => {
        account = res[0]
      })
      return account
    } catch (e) {
      console.log(e.message);
    }
  }


  // 领取邀请奖励
  async claim(markle_index, reward_un_claim, markle_prove) {
    try {
      const addr = await this.getCurrentAccount()
      const res = await this.inviteContract.methods.claim(markle_index, addr, reward_un_claim, markle_prove).send({ from: addr })
      return res
    } catch (err) {
      return err
    }
  }
}

export default MerkleDistributor;
