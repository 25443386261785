import Web3 from "web3";
import BigNumber from "bignumber.js";
import config from "../config";
import erc20ABI from "./abi/token.json";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

// eth地址
const ETH_ADDR = networkConfig.mainChainAddr;

class Erc20Service {
  web3;
  erc20Contract;

  constructor() {
    try {
      if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.erc20Contract = new this.web3.eth.Contract(
        erc20ABI,
        networkConfig.ismAddr
      );
    } catch (e) {
      console.log(e.message);
    }
  }
  // 获取当前钱包地址
  async getCurrentAccount() {
    try {
      return window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(res => {
          return res[0];
        });
    } catch (e) {
      console.log(e.message);
    }
  }
  // 获得链币余额
  async getBalanceOfChain() {
    try {
      let account = (await this.getCurrentAccount());
      const res = window.ethereum.getBalance(config.mainChainAddr, account)
      return res;
    } catch (e) {
      console.log(e.message);
    }
  }
  // 获取币种的名称
  async getTokenSymbol(tokenAddress) {
    try {
      if (tokenAddress === ETH_ADDR) {
        return "ETH";
      }
      const contract = new this.web3.eth.Contract(erc20ABI, tokenAddress);
      const symbol = await contract.methods.symbol().call();
      return symbol.toUpperCase();
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 获取币种的精度
  async getTokenDecimals(tokenAddress) {
    try {
      if (tokenAddress === ETH_ADDR) {
        return 18;
      }
      const contract = new this.web3.eth.Contract(erc20ABI, tokenAddress);
      return await contract.methods.decimals().call();
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 获取币种余额
  async getTokenBalance(tokenAddress, account) {
    account = account || (await this.getCurrentAccount());
    if (tokenAddress === ETH_ADDR || !tokenAddress) {
      const balance = await this.web3.eth.getBalance(account);
      return this.web3.utils.fromWei(balance);
    }
    const contract = new this.web3.eth.Contract(erc20ABI, tokenAddress);
    const decimals = await contract.methods.decimals().call();
    const balance = await contract.methods.balanceOf(account).call();

    return new BigNumber(balance)
      .div(new BigNumber(10).pow(decimals))
      .toString();
  }

  // 创建Erc20Contract
  async getErc20Contract(abi, contractAddress) {
    try {
      const _contract = new this.web3.eth.Contract(abi, contractAddress);
      this.web3.eth.net.givenProvider.chainId = 0x22b8;
      return _contract;
    } catch (e) {
      console.log(e);
    }
  }

  // 检查授权金额是否足够

  // 检查授权
  async checkApproveAmount(token, contractAddress, inputAmount) {
    const account = await this.getCurrentAccount();
    const tokenDecimals = await this.getTokenDecimals(token);
    const contract = await this.getErc20Contract(erc20ABI, token);
    const amount = await contract.methods
      .allowance(account, contractAddress)
      .call();
    const approve = new BigNumber(amount)
      .div(new BigNumber(10).pow(tokenDecimals))
      .toString();
    return +inputAmount > +approve
  }


  // 检查授权
  async checkApprove(token, contractAddress) {
    // if (token === ETH_ADDR) {
    //   return true;
    // }
    const account = await this.getCurrentAccount();

    const tokenDecimals = await this.getTokenDecimals(token);
    const contract = await this.getErc20Contract(erc20ABI, token);
    const amount = await contract.methods
      .allowance(account, contractAddress)
      .call();

    return new BigNumber(amount)
      .div(new BigNumber(10).pow(tokenDecimals))
      .toString();

    // return amount;
    // return Number(amount) > 0;
  }

  // 进行授权
  async approveToken(token, spenderAddress) {
    try {
      const account = await this.getCurrentAccount();
      const approveValue = this.web3.utils.toWei("30000000", "ether");
      const contract = await this.getErc20Contract(erc20ABI, token);
      return await contract.methods
        .approve(spenderAddress, approveValue)
        .send({ from: account });
    } catch (e) {
      return e;
    }
  }
}

export default Erc20Service;
